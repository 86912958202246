import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "./_index.scss";

function Input(props) {
  const {
    label,
    name,
    className,
    classNameIcon,
    withIcon,
    leftIcon,
    rightIcon,
    onClick,
    errorMessage,
    errorWraper,
    color,
    isDisabled,
    ...rest
  } = props;
  return (
    <>
      {!withIcon && (
        <div
          className={
            isDisabled
              ? `disabled-input form-control ${className}`
              : `form-control ${className}`
          }
        >
          {label && <label htmlFor={name}>{label}</label>}
          <div className={` ${errorWraper}`}>
            <Field
              id={name}
              name={name}
              style={{ color: color ? color : "" }}
              {...rest}
            />
            {errorMessage ? (
              <p className="error">
                <ReportProblemIcon /> {errorMessage}
              </p>
            ) : (
              <ErrorMessage component={TextError} name={name} />
            )}
          </div>
        </div>
      )}

      {withIcon && (
        <div className={className}>
          {label && <label htmlFor={name}>{label}</label>}
          <div className="field-global-wrapper">
            <div className="field-intern-wrapper">
              {leftIcon && typeof leftIcon === "string" ? (
                <img src={leftIcon} alt={leftIcon} />
              ) : (
                leftIcon
              )}
              <Field id={name} name={name} {...rest} />
            </div>
            {rightIcon && typeof rightIcon === "string" ? (
              <img src={rightIcon} alt={rightIcon} onClick={onClick} />
            ) : (
              rightIcon
            )}
          </div>
          {errorMessage ? (
            <p className="error">
              <ReportProblemIcon />
              {errorMessage}
            </p>
          ) : (
            <ErrorMessage component={TextError} name={name} />
          )}
        </div>
      )}
    </>
  );
}

export default Input;
