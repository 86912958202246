import React, { useEffect } from "react";
import { useColor } from "react-color-palette";
import "./colorPicker.scss";

const ColorPickerComponent = ({
  setValue,
  name,
  formik,
  initialColor,
  setResetValue,
  resetValue,
}) => {
  const [color, setColor] = useColor("#121212");

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
    }
  }, []);

  const onChangeHandler = (event) => {
    setColor(event.target.value);
    setValue(event.target.value);
    formik.values.color = event.target.value;
    setResetValue(undefined);
  };

  return (
    <div className="color-picker-component">
      <input
        id={name}
        name={name}
        type="color"
        color={color}
        onChange={(event) => onChangeHandler(event)}
        value={resetValue ? "#121212" : color}
      />
    </div>
  );
};

export default ColorPickerComponent;
