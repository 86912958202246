import React, { useState } from "react";
import DateView from "react-datepicker";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "./_index.scss";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "./_datePicker.scss";

const CostumDatePicker = (props) => {
  const {
    label,
    name,
    className,
    classNameIcon,
    withIcon,
    leftIcon,
    rightIcon,
    onClick,
    rangeDays,
    selectedDay,
    setSelectedDay,
    selectedDayRange,
    setSelectedDayRange,
    error,
    formik,
    placeholder,
    isDisabled,
    calendarPopperPosition,
    ...rest
  } = props;

  const field = formik?.getFieldProps(name);
  return (
    <>
      {!withIcon && (
        <div className={`form-control ${className}`}>
          {label && <label htmlFor={name}>{label}</label>}
          <div
            className={`${isDisabled
                ? "disabled-input costum-date-picker "
                : "costum-date-picker"
              } `}
          >
            <DatePicker
              value={field?.value}
              onChange={(value) => formik.setFieldValue(name, value)}
              inputPlaceholder={placeholder}
              colorPrimary="#0fbcf9"
              colorPrimaryLight="rgba(75, 207, 250, 0.4)"
              shouldHighlightWeekends
              calendarPopperPosition={calendarPopperPosition}
            />
            {error ? (
              <p className="error">
                <ReportProblemIcon /> {error}
              </p>
            ) : null}
          </div>
        </div>
      )}

      {withIcon && (
        <div className={` ${className}`}>
          {label && <label htmlFor={name}>{label}</label>}
          <div>
            <div className="field-global-wrapper">
              <div className="field-intern-wrapper">
                {leftIcon && typeof leftIcon === "string" ? (
                  <img src={leftIcon} alt={leftIcon} />
                ) : (
                  leftIcon
                )}
                <DatePicker
                  value={field?.value}
                  onChange={(value) => formik?.setFieldValue(name, value)}
                  inputPlaceholder={placeholder}
                  colorPrimary="#0fbcf9"
                  colorPrimaryLight="rgba(75, 207, 250, 0.4)"
                  shouldHighlightWeekends
                  calendarPopperPosition={calendarPopperPosition}
                />
              </div>
              {rightIcon && typeof rightIcon === "string" ? (
                <img src={rightIcon} alt={rightIcon} onClick={onClick} />
              ) : (
                rightIcon
              )}
            </div>
            {error ? (
              <p className="error">
                <ReportProblemIcon /> {error}
              </p>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default CostumDatePicker;
